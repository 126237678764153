.header {
  background: #191919 !important;
  color: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
}

.header .css-6xja36:hover {
  color: #191919 !important;
}
.header .link-header {
  padding: 20px 10px;
}
.header .active-link {
  border-bottom: 2px red solid;
}
